exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-node-dlc-page-path-alias-js": () => import("./../../../src/pages/{NodeDlcPage.path__alias}.js" /* webpackChunkName: "component---src-pages-node-dlc-page-path-alias-js" */),
  "component---src-pages-other-tags-taxonomy-term-dlc-tags-name-js": () => import("./../../../src/pages/other-tags/{TaxonomyTermDlcTags.name}.js" /* webpackChunkName: "component---src-pages-other-tags-taxonomy-term-dlc-tags-name-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-views-most-recent-js": () => import("./../../../src/pages/views/most-recent.js" /* webpackChunkName: "component---src-pages-views-most-recent-js" */),
  "component---src-pages-website-categories-taxonomy-term-dlc-category-name-js": () => import("./../../../src/pages/website-categories/{TaxonomyTermDlcCategory.name}.js" /* webpackChunkName: "component---src-pages-website-categories-taxonomy-term-dlc-category-name-js" */),
  "component---src-templates-learning-object-template-js": () => import("./../../../src/templates/learningObjectTemplate.js" /* webpackChunkName: "component---src-templates-learning-object-template-js" */)
}

