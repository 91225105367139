const React = require("react");

// Fix issue where scroll possition is erroneously maintained after clicking a link to a resource
// See: https://github.com/gatsbyjs/gatsby/issues/38201#issuecomment-1631378720
exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    const currentPosition = getSavedScrollPosition(location);
    if (!location.hash){
        setTimeout(() => {
            window.scrollTo({ top: (currentPosition[0] || 0), left: (currentPosition[1] || 0), behavior: 'instant' });
        }, 0);
        return false;
    }
};